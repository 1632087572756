<template>
  <div
    class="surface-ground px-4 py-8 md:px-6 lg:px-8 flex align-items-center justify-content-center"
  >
    <div class="surface-card p-4 shadow-2 border-round w-full lg:w-4">
      <div class="text-center mb-5">
        <div class="text-center mb-5">
          <img
            src="images/blocks/logos/icono.svg"
            alt="Image"
            height="90"
            class="mb-0"
          />
          <div class="text-900 text-3xl font-medium mb-3">BIENVENIDO</div>
          <span class="text-600 font-medium line-height-3"
            >Ingrese sus credenciales para acceder al sistema</span
          >
        </div>
      </div>
      <div class="w-full md:w-12 mx-auto">
        <Form
          @submit="onSubmit"
          :loading="cargando"
          :validation-schema="schema"
          v-slot="{ errors, isSubmitting }"
        >
          <div class="form-group">
            <label class="block text-900 font-medium mb-2"
              ><strong>CORREO ELECTR&Oacute;NICO</strong></label
            >
            <Field
              name="email"
              type="email"
              class="w-full mb-0"
              placeholder="Su Correo Electrónico"
              style="padding: 1rem"
              :class="{ 'is-invalid': errors.email }"
              @input="autocompleteEmail"
            />
            <div class="invalid-feedback">{{ errors.email }}</div>
          </div>
          <div class="form-group">
            <label class="block text-900 font-medium mb-2 mt-4"
              ><strong>CONTRASE&Ntilde;A</strong></label
            >
            <Field
              name="password"
              type="password"
              class="w-full mb-0"
              placeholder="Su contraseña"
              style="padding: 1rem"
              :class="{ 'is-invalid': errors.password }"
            />
            <div class="invalid-feedback mb-4">{{ errors.password }}</div>
          </div>
          <div class="flex align-items-center justify-content-between mb-5">
            <Button
              label="Iniciar Sesión"
              icon="pi pi-sign-in"
              type="submit"
              class="w-full p-3 text-xl"
              :disabled="isSubmitting"
            />
          </div>
          <div class="flex align-items-center justify-content-between mb-5">
            <h5 v-show="isSubmitting">
              Conectando, Por favor espera...
              <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
            </h5>
          </div>
        </Form>
      </div>
      <div class="text-center mt-5" v-show="false">
        <span class="text-800 font-medium line-height-3"
          ><strong>DESARROLLADO POR: </strong>
          <a href="https://sitbolivia.com/" target="_blank"
            ><strong>SIT-BOLIVIA</strong></a
          ></span
        >
        <span class="text-800 font-medium line-height-3">
          | <strong>SOPORTE</strong> : <span class="pi pi-phone"></span>
          <a href="https://wa.me/59163084946">63084946</a>
        </span>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useRouter } from "vue-router";
import { Form, Field } from "vee-validate";
import * as Yup from "yup";
import { useAuth } from "@/stores";
import { onBeforeMount } from "vue";

const schema = Yup.object().shape({
  email: Yup.string().required("Ingrese Correo").email("Correo Invalido!"),
  password: Yup.string()
    .min(8, "La contraseña debe contener más de 8 caracteres!")
    .required("Ingrese Contraseña!"),
});
let cargando = false;
async function onSubmit(values) {
  const authStore = useAuth();
  //const { email, password } = values;
  await authStore.userLogin(values);
  cargando = true;
}

onBeforeMount(() => {
  if (localStorage.getItem("token")) useRouter().push("/");
});

function autocompleteEmail(e) {
  const input = e.target;
  const value = input.value.trim();

  if (value.length > 2) {
    if (value.includes("@")) {
      input.value = value;
    } else {
      input.value = value + "@";
    }
  }
}
</script>

<style scoped>
.pi-eye {
  transform: scale(1.6);
  margin-right: 1rem;
}

.pi-eye-slash {
  transform: scale(1.6);
  margin-right: 1rem;
}
.invalid-feedback {
  color: red;
}
</style>
